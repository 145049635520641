import useNavigationTabs from 'components/hooks/useNavigationTabs';
import {Box, Button, Stack} from 'components/mui';
import type {NavigationTab} from 'components/types';
import {BodyCard} from 'components/ui/cards/BodyCard';
import {PageLoadingSpinner} from 'components/ui/graphics/PageLoadingSpinner';
import Link from 'components/ui/links/Link';
import {PrivatePage} from 'components/ui/page/PrivatePage';
import {SubPage} from 'components/ui/page/SubPage';
import useEditAicpSettings, {
    EditAicpSettings,
} from 'components/ui/pages/aicp/edit-settings/useEditAicpSettings';
import {
    PAGE_ID_AICP_SETTINGS_TUNING,
    PAGE_ID_AICP_SETTINGS_USER_ASSIGNMENT,
} from 'components/util/constants';
import {AICPS_URL, AICP_URL} from 'components/util/routeConstants';
import {Notification} from 'core/Notification/index';
import {
    Dispatch,
    MutableRefObject,
    SetStateAction,
    useRef,
    useState,
} from 'react';
import {logger} from 'utils/logger';

export const AICP_SETTINGS_PAGE_USER_ASSIGNMENT_TAB_ROUTE =
    'edit-settings/user-assignment';

const onQueryErrorCallback = () => {
    logger.error('An error prevented this page from loading correctly.');
};

const onMutationErrorCallback = () => {
    Notification.error(
        'Failed to update the aiCP settings. Please, wait a moment and try again.'
    );
};

const onMutationSuccessCallback = () => {
    Notification.success('Success! aiCP settings updated.', {
        removeAfter: 5,
    });
};

export const aicpSettingsNavigationTabs: NavigationTab[] = [
    {
        pageId: PAGE_ID_AICP_SETTINGS_USER_ASSIGNMENT,
        label: 'User Assignment',
        route: AICP_SETTINGS_PAGE_USER_ASSIGNMENT_TAB_ROUTE,
    },
    {
        pageId: PAGE_ID_AICP_SETTINGS_TUNING,
        label: 'Tuning',
        route: 'edit-settings/tuning',
    },
];

interface ChildrenFormProps {
    editAicpSettings: EditAicpSettings;
    formRef: MutableRefObject<HTMLFormElement>;
    setAllowSubmitting: Dispatch<SetStateAction<boolean>>;
}

interface AicpSettingsSubPageProps {
    aicpId: string;
    pageId: string;
    children?: React.FunctionComponent<ChildrenFormProps>;
}

const AicpSettingsSubPage = ({
    aicpId,
    pageId,
    children,
}: AicpSettingsSubPageProps) => {
    const formRef = useRef<HTMLFormElement>();
    const [allowSubmitting, setAllowSubmitting] = useState<boolean>(true);

    const {navigationTabs, currentPageNavigationTab} = useNavigationTabs(
        aicpSettingsNavigationTabs,
        pageId
    );

    const editAicpSettings = useEditAicpSettings({
        pageId,
        aicpId,
        onQueryErrorCallback,
        onMutationSuccessCallback,
        onMutationErrorCallback,
    });

    const {aicp, isQueryLoading, isMutationLoading} = editAicpSettings;

    const submitForm = () => {
        if (formRef.current) formRef.current.requestSubmit();
    };

    const submitButtonClickHandler = () => {
        submitForm();
    };

    const isSubmitButtonDisabled: boolean = [
        isQueryLoading,
        isMutationLoading,
        !allowSubmitting,
    ].some(Boolean);

    const submitButtonText: string = isMutationLoading
        ? 'Saving aiCP settings...'
        : 'Save aiCP settings';

    const pageTitle: string =
        (aicp?.name ? aicp?.name + ' :: ' : '') + 'aiCP settings';

    return (
        <PrivatePage pageId={pageId} contentStyles={{padding: '0px'}}>
            <SubPage
                title={pageTitle}
                navigationTabsBaseUrl={`${AICP_URL}/${aicpId}`}
                navigationTabs={navigationTabs}
                currentPageNavigationTab={currentPageNavigationTab}
                headerContent={
                    <Stack direction="row" spacing={2} alignItems="baseline">
                        <Link href={AICPS_URL}>
                            <Button
                                type="button"
                                size="medium"
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                        </Link>

                        <Button
                            size="medium"
                            disabled={isSubmitButtonDisabled}
                            aria-disabled={isSubmitButtonDisabled}
                            onClick={submitButtonClickHandler}
                        >
                            {submitButtonText}
                        </Button>
                    </Stack>
                }
            >
                <BodyCard className="form-section">
                    {isQueryLoading && (
                        <Box mb={12}>
                            <PageLoadingSpinner />
                        </Box>
                    )}

                    {!isQueryLoading &&
                        children &&
                        children({
                            editAicpSettings,
                            formRef,
                            setAllowSubmitting,
                        })}
                </BodyCard>
            </SubPage>
        </PrivatePage>
    );
};

export default AicpSettingsSubPage;
