import React from 'react';
import {Card} from 'components/mui';
import styled from 'styled-components';

const StyledCard = styled(Card)`
    margin-bottom: 15px;
    padding: 22px 32px;

    h1 {
        font-size: 1.8rem;
    }

    &.MuiPaper-root {
        border-radius: 0px;
        overflow: visible;
    }
`;

export interface BodyCardProps {
    children: React.ReactNode;
    className?: string;
}

function BodyCard({children}: BodyCardProps) {
    return <StyledCard elevation={1}>{children}</StyledCard>;
}

export {BodyCard};
