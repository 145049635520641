/* eslint-disable complexity */
import {useCallback} from 'react';
import {FetchResult} from '@apollo/client';
import _ from 'lodash';

import {FieldOption} from 'components/ui/pages/connectors/salesforce/useCategoryFieldOptionsQuery';
import type Aicp from 'core/Aicp/index';
import {DropdownOption} from 'components/types';
import {useMutation, useQuery} from 'graphql/client';
import AICP_SETTINGS from 'graphql/pages/aicp/aicpSettings.graphql';
import AICP_USER_OPTIONS from 'graphql/pages/aicp/aicpUserOptions.graphql';
import UPDATE_AICP from 'graphql/pages/aicp/updateAicp.graphql';
import IDEAL_CUSTOMER_PROFILES from 'graphql/pages/aicps/Aicps.graphql';
import ASSIGNED_ACCOUNT_TOTAL from 'graphql/user/queries/assignedAccountTotal.graphql';
import ASSIGNED_ACCOUNTS from 'graphql/user/queries/assignedAccounts.graphql';
import USER_AICPS_ACCOUNT_COUNTS from 'graphql/user/queries/userAicpsAccountCounts.graphql';
import type {SelectedExegraphic} from 'core/ExegraphicLens';
import {Logger} from 'utils/logger';
import {logErrorMessages} from 'utils/graphqlResponse';
import {PAGE_ID_AICP_SETTINGS_TUNING} from 'components/util/constants';

const logger = new Logger('useEditAicpSettings');

const DEBOUNCE_TIMER: number = 1 * 1000;

interface AicpToEditQueryProps {
    data: {
        idealCustomerProfile?: {
            idealCustomerProfile?: Aicp;
        };
    };
    loading: boolean;
}

interface AicpUserOptionsQueryProps {
    data: {
        icpFilterOptions?: {
            userOptions?: DropdownOption[];
        };
    };
    loading: boolean;
}

export interface AimSelectedExegraphics {
    customExegraphicLenses: SelectedExegraphic[];
    exegraphicLenses: SelectedExegraphic[];
}

export interface UseEditAicpSettingsProps {
    pageId: string;
    aicpId: string;
    onMutationSuccessCallback?: () => void;
    onMutationErrorCallback?: () => void;
    onQuerySuccessCallback?: () => void;
    onQueryErrorCallback?: () => void;
}

export type FlagsToClear = 'all' | 'aimExegraphics' | 'fieldMappings';

export interface EditAicpSettings {
    aicp?: Aicp;
    assignedSdrUserOptions?: DropdownOption[];
    getSelectedAssignedSdrUsers?: () => DropdownOption[];
    editAicpSettingsMutation: (
        assignedSdrUserIds: string[]
    ) => Promise<FetchResult<unknown>>;
    isQueryLoading: boolean;
    isMutationLoading: boolean;
    salesforceFieldOptions?: FieldOption[];
}

export interface AicpSettingsDirtyState {
    fieldMappings: boolean;
    aimExegraphics: boolean;
}

const useEditAicpSettings = ({
    pageId,
    aicpId,
    onMutationSuccessCallback,
    onMutationErrorCallback,
    onQuerySuccessCallback,
    onQueryErrorCallback,
}: UseEditAicpSettingsProps): EditAicpSettings => {
    const isTuningPage: boolean = pageId === PAGE_ID_AICP_SETTINGS_TUNING;

    const debouncedOnQuerySuccessCallback = onQuerySuccessCallback
        ? _.debounce(onQuerySuccessCallback, DEBOUNCE_TIMER, {
              leading: true,
              trailing: false,
          })
        : undefined;
    const debouncedOnQueryErrorCallback = onQueryErrorCallback
        ? _.debounce(onQueryErrorCallback, DEBOUNCE_TIMER, {
              leading: true,
              trailing: false,
          })
        : undefined;

    const {data: aicpData, loading: isAicpDataLoading}: AicpToEditQueryProps =
        useQuery(AICP_SETTINGS, {
            variables: {
                idealCustomerProfileId: aicpId,
            },
            fetchPolicy: 'network-only',
            onCompleted() {
                if (debouncedOnQuerySuccessCallback)
                    debouncedOnQuerySuccessCallback();
            },
            onError(error) {
                logErrorMessages(error, logger);

                if (debouncedOnQueryErrorCallback)
                    debouncedOnQueryErrorCallback();
            },
        });

    const {
        data: userOptionsData,
        loading: isUserOptionsLoading,
    }: AicpUserOptionsQueryProps = useQuery(AICP_USER_OPTIONS, {
        skip: isTuningPage,
        variables: {
            idealCustomerProfileId: aicpId,
        },
        fetchPolicy: 'network-only',
        onCompleted() {
            if (debouncedOnQuerySuccessCallback)
                debouncedOnQuerySuccessCallback();
        },
        onError(error) {
            logErrorMessages(error, logger);

            if (debouncedOnQueryErrorCallback) debouncedOnQueryErrorCallback();
        },
    });

    const [executeMutation, {loading: isMutationLoading}] = useMutation(
        UPDATE_AICP,
        {
            refetchQueries: [
                {
                    query: AICP_SETTINGS,
                    variables: {idealCustomerProfileId: aicpId},
                },
                {query: IDEAL_CUSTOMER_PROFILES},
                {query: ASSIGNED_ACCOUNTS},
                {query: ASSIGNED_ACCOUNT_TOTAL},
                {query: USER_AICPS_ACCOUNT_COUNTS},
            ],
            onCompleted() {
                if (onMutationSuccessCallback) onMutationSuccessCallback();
            },
            onError(error) {
                logErrorMessages(error, logger);

                if (onMutationErrorCallback) onMutationErrorCallback();
            },
        }
    );

    const editAicpSettingsMutation = (
        assignedSdrUserIds: string[]
    ): Promise<FetchResult<unknown>> => {
        return executeMutation({
            variables: {
                aicpId,
                assignedSdrUserIds,
            },
        });
    };

    const isQueryLoading: boolean = isAicpDataLoading || isUserOptionsLoading;

    const aicp: Aicp = aicpData?.idealCustomerProfile?.idealCustomerProfile;
    const assignedSdrUserOptions: DropdownOption[] =
        userOptionsData?.icpFilterOptions?.userOptions ?? [];

    const getSelectedAssignedSdrUsers = useCallback((): DropdownOption[] => {
        const currentAssignedSdrUsers = aicp?.assignedSdrUsers?.map(
            (assignedSdrUser) => assignedSdrUser.user.id
        );

        if (
            currentAssignedSdrUsers?.length === assignedSdrUserOptions?.length
        ) {
            // empty array means all users are assigned to this aicp
            return [];
        }

        return assignedSdrUserOptions?.filter((userOption) =>
            currentAssignedSdrUsers?.includes(userOption.value as string)
        );
    }, [aicp?.assignedSdrUsers, assignedSdrUserOptions]);

    return {
        aicp, // used
        assignedSdrUserOptions, //
        getSelectedAssignedSdrUsers, //
        editAicpSettingsMutation, //
        isQueryLoading, //
        isMutationLoading, //
    };
};

export default useEditAicpSettings;
